import React, { useRef } from "react";

import SEO from "../../components/seo";
import Layout from "../../components/layout";
import ProjectHero from "../../modules/hero/project-hero";
import CoverSection from "../../components/project/cover-section";
import ProjectDetails from "../../components/project/project-details";
import Blitz1 from "../../images/blitz/1.jpeg";
import Blitz2 from "../../images/blitz/2.jpeg";
import Blitz3 from "../../images/blitz/3.jpeg";
import Blitz4 from "../../images/blitz/4.jpeg";
import Blitz5 from "../../images/blitz/5.jpeg";
import Blitz6 from "../../images/blitz/6.jpeg";
import FooterMapDetails from "../../components/footer/footer-map-details";

const images = [
  { src: Blitz2, title: "project Blitz2" },
  { src: Blitz3, title: "project Blitz3" },
  { src: Blitz4, title: "project Blitz4" },
  { src: Blitz5, title: "project Blitz5" },
  { src: Blitz6, title: "project Blitz6" },
];

const ProjectTemplate = ({ location }) => {
  const seo = {
    pageUrl: "/projects/blitz",
    pageTitle: "Blitz App | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "Blitz App | Vello | A full-service creative agency.",
  };

  const pageLayoutRef = useRef();
  const coverSectionUnique = `blitz-section`;

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ProjectHero
          title={"Blitz App"}
          client={"Blitz App"}
          output={"Web and Desktop App"}
          publishDate={"2020"}
          externalLink={"https://blitz.gg/"}
          coverSectionUnique={coverSectionUnique}
          pageLayoutRef={pageLayoutRef}
        />
        <CoverSection
          coverImage={Blitz1}
          coverSectionUnique={coverSectionUnique}
        />

        <ProjectDetails
          overview={
            "Blitz is redefining competitive gaming with a revolutionary desktop app for some of the most popular desktop game like League of Legends, TFT, Runeterra, and Valorant. Blitz enhances player experiences by empowering them with expert analytics designed to help them improve and win more."
          }
          ourSolution={
            "Blitz, being an esports company, has a very different userbase than most apps we find today. With the specificity of the userbase we had to make sure all of our work was geared specially towards them. The desktop app seamlessly integrates with both Windows and Mac operating systems to hook into supported games in order to retrieve live game data late used for analysis. Through our work, we were able to increase their DAU (Daily Active Users) from 400k to over 1.3MM+."
          }
          highlightedText={
            "Blitz is one of the fastest growing esports companies, with Vello as a partner, they are leading they way to get into pro play analysis and to coach pro esports teams."
          }
          images={images}
          nextPageLink={"/projects/associate-com"}
          pageLayoutRef={pageLayoutRef}
        />
        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default ProjectTemplate;
